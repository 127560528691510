import React, { useRef, useState } from 'react'
import Cropper, { ReactCropperElement } from 'react-cropper'
import toast from 'react-hot-toast'
import 'cropperjs/dist/cropper.css'
import { useTranslation } from 'next-i18next'
import { BadRequest } from 'shared/errors/bad-request'
import { useUploadImage } from 'shared/hooks/use-upload-image'
import { DataFile } from 'shared/types/datafile-type'
import { FieldErrorAndDescription } from '../../form/field-error-and-description'
import Modal, { ModalProps } from '../../modal'
import PrimaryButton from '../../primary-button'
import { FileUploadErrorResponseInterface } from '../types'

export type ImageCropperType = Omit<React.ComponentPropsWithoutRef<typeof Cropper>, 'src'>

export type ImageCropperAndUploaderProps = Pick<ModalProps, 'onClose'> & {
  onUploadSuccess: (imageFileData: DataFile) => void
  imageFile: File | undefined
  source?: string
  type?: string
  isOpened: boolean
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  imageCropperProps?: ImageCropperType
  isRounded?: boolean
}

export const ImageCropperAndUploader = ({
  imageCropperProps: imageCropperProps,
  imageFile,
  type,
  source,
  isOpened,
  setIsOpened,
  onClose,
  onUploadSuccess,
  isRounded,
}: ImageCropperAndUploaderProps) => {
  const {
    viewMode = 1,
    autoCropArea = 1,
    aspectRatio = 1,
    checkOrientation = false, // NOTE: it fixes this issue https://github.com/fengyuanchen/cropperjs/issues/671
    background = true,
    dragMode = 'move',
    toggleDragModeOnDblclick = false,
    style = { height: 400, width: '100%' },
    ...cropperPropsRest
  } = imageCropperProps || {}

  const { t } = useTranslation()

  const cropperRef = useRef<ReactCropperElement>(null)
  const cropperWrapperRef = useRef<HTMLDivElement>(null)
  const previewRef = useRef<HTMLDivElement>(null)
  const finalPreviewRef = useRef<HTMLDivElement>(null)

  const [uploadError, setUploadError] = useState<string | null>(null)

  const { uploadImage, isFetching } = useUploadImage()

  const hidePreview = () => {
    if (cropperWrapperRef.current) cropperWrapperRef.current.style.opacity = ''

    if (previewRef.current) previewRef.current.style.display = 'block'
    if (finalPreviewRef.current) {
      finalPreviewRef.current.style.zIndex = ''
      finalPreviewRef.current.innerText = ''
    }
  }
  const showPreview = () => {
    //  NOTE: The Cropper resets the cropBox after render because of that we overlay it with the finalPreviewRef.
    // We can't use the previewRef because it's controlled by the Cropper, so it changes after render too.
    if (previewRef.current && finalPreviewRef.current) {
      finalPreviewRef.current.appendChild(previewRef.current.cloneNode(true))
      finalPreviewRef.current.style.zIndex = '1000'
      previewRef.current.style.display = 'none'
    }

    //  NOTE: we use the cropperWrapperRef because applying styles to the cropperRef is buggy
    if (cropperWrapperRef.current) cropperWrapperRef.current.style.opacity = '0'
  }

  const onLoad = async function () {
    try {
      if (typeof cropperRef.current?.cropper !== 'undefined') {
        showPreview()

        const cropOptions = cropperRef.current?.cropper.getData()
        const imageFileData = await uploadImage({
          file: imageFile as File,
          source,
          type,
          cropOptions,
        })
        onUploadSuccess(imageFileData)
        setIsOpened(false)
      }
    } catch (e) {
      if (e instanceof BadRequest) {
        const errors = (e as unknown as FileUploadErrorResponseInterface).errors
        if (Array.isArray(errors)) {
          setUploadError(errors.join('\n'))
        } else if ('common' in errors && Array.isArray(errors.common)) {
          setUploadError(errors.common.join('\n'))
        } else if (
          'image' in errors &&
          'file' in errors.image &&
          Array.isArray(errors?.image?.file)
        ) {
          setUploadError(errors.image.file.join(''))
        }
      }
      hidePreview()
      toast.error(t('global.error'))
    }
  }

  return (
    <Modal
      opened={isOpened}
      onClose={() => {
        setIsOpened(false)
      }}
      afterLeave={() => {
        setUploadError(null)
        onClose()
        hidePreview()
      }}
      title={t('global.upload_image')}
    >
      <div className="relative w-full overflow-hidden">
        <div ref={finalPreviewRef} className="absolute inset-0 flex items-center justify-center" />
        <div
          ref={previewRef}
          style={{
            position: 'absolute',
            overflow: 'hidden',
            width: style.width,
            height: style.height,
            borderRadius: isRounded ? '50%' : '0',
          }}
        />
        <div ref={cropperWrapperRef}>
          <Cropper
            className={isRounded ? 'sio-cropper-rounded' : ''}
            ref={cropperRef}
            src={imageFile && URL.createObjectURL(imageFile)}
            preview={(previewRef || undefined) as any} // NOTE: need it to get rid of null (the react-cropper throws an error because of null)
            style={style}
            viewMode={viewMode}
            background={background}
            autoCropArea={autoCropArea}
            aspectRatio={aspectRatio}
            checkOrientation={checkOrientation}
            dragMode={dragMode}
            toggleDragModeOnDblclick={toggleDragModeOnDblclick}
            {...cropperPropsRest}
          />
        </div>
      </div>
      <FieldErrorAndDescription error={uploadError} errorClassName={'text-center mt-2'} />
      <PrimaryButton className="ml-auto mt-2" onClick={onLoad} isLoading={isFetching}>
        {t('global.click_to_upload')}
      </PrimaryButton>
    </Modal>
  )
}
