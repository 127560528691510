import React, { useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import UploadIcon from 'shared/icons/upload-icon'
import { HTMLString } from 'shared/types/html-string'
import { FileInputAcceptType, isAcceptTypeValid } from 'shared/utils/file-input-accept-types'
import { FieldErrorAndDescription } from './form/field-error-and-description'

export enum FileSelectorVariantEnum {
  small,
  medium,
  big,
}

const variantClassName = {
  [FileSelectorVariantEnum.small]: 'p-2',
  [FileSelectorVariantEnum.medium]: 'p-3',
  [FileSelectorVariantEnum.big]: 'p-10',
}

export type FileSelectorProps = React.PropsWithChildren<{
  className?: string
  dropZoneClassName?: string
  disabled?: boolean
  label?: React.ReactNode
  onSelectFile: (file: File) => void
  isPreFetching?: boolean
  acceptTypes?: FileInputAcceptType[]
  error?: HTMLString
  icon?: JSX.Element | null
  variant?: FileSelectorVariantEnum
}>

export function FileSelector({
  acceptTypes,
  children,
  dropZoneClassName,
  className,
  disabled,
  error,
  variant = FileSelectorVariantEnum.medium,
  label,
  icon = <UploadIcon />,
  onSelectFile,
  isPreFetching,
}: FileSelectorProps) {
  const { t } = useTranslation()

  const [isDragging, setIsDragging] = useState(false)
  const [fileTypeError, setFileTypeError] = useState('')

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      if (acceptTypes && !isAcceptTypeValid(file.type, acceptTypes)) {
        setFileTypeError(t('file_selector.file_not_supported'))
      } else {
        onSelectFile(file)
      }
    }
    // NOTE: Reset value to have the ability to open the same file https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
    e.target.value = ''
  }

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer
    if (files && files.length) {
      const file = files[0]
      onSelectFile(file)
    }
    setIsDragging(false)
  }

  return (
    <label className={`font-medium ${className || ''}`}>
      <input
        type="file"
        className="invisible absolute h-1 w-0"
        onChange={handleSelectFile}
        disabled={isPreFetching || disabled}
      />
      <div
        className={`flex flex-col items-center justify-center gap-1 rounded-lg border border-dashed bg-lightblue ${
          isDragging ? 'border-blue' : 'border-gray'
        } ${variantClassName[variant]} h-full ${isPreFetching ? 'animate-pulse' : ''} ${
          disabled || isPreFetching ? 'pointer-events-none' : 'cursor-pointer hover:border-blue'
        } ${dropZoneClassName || ''}`}
        onDragStart={handleDrag}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={handleDrag}
      >
        {children ? (
          children
        ) : (
          <>
            {icon}
            {label}
          </>
        )}
      </div>
      <FieldErrorAndDescription error={fileTypeError} errorClassName={'text-center mt-2'} />
      <FieldErrorAndDescription error={error} errorClassName={'text-center mt-2'} />
    </label>
  )
}
